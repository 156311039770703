import styles from './FileInput.module.scss';
import { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';

const FileInput = ({ id, value, onChange, onClear, multiple = false, additionalInfoData = {},...attributes }) => {
  const inputRef = useRef();
  const onFileChange = e => {
    e.target.files.length && onChange(multiple ? e.target.files : e.target.files[0]);
  };

  const selectedFileName = getSelectedFileName(value, multiple);

  const { formikRef, onBlurAdditionalInfo } = additionalInfoData;
  const onSubmitAdditionalInfo = e => {
    if (!formikRef || !formikRef.current || !onBlurAdditionalInfo)
      return;

    const nowAttachedFiles = multiple ? e.dataTransfer.files : e.dataTransfer.files[0];

    formikRef.current.values.attachCheckoutFileName = nowAttachedFiles;
    formikRef.current.dirty = true;
    onBlurAdditionalInfo(e, formikRef.current);
  };

  const onFileClear = e => {
    e.preventDefault();
    inputRef.current.value = null;
    onChange();
    onClear();
  };

  const onCancelBtnKeyDown = e => {
    if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
      onFileClear(e);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    e.currentTarget.classList.remove(styles.dragOver);
    onChange(multiple ? e.dataTransfer.files : e.dataTransfer.files[0]);
    onSubmitAdditionalInfo(e);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={styles.container}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input ref={inputRef} id={id} type="file" multiple={multiple} {...attributes} onChange={onFileChange} className="visually-hidden" />
      <label htmlFor={id}>
        <span className={styles.labelWrapper}>
          {value
            ? multiple && value.length > 1
              ? <SimpleText textKey="FileUploader_SelectedFilesCount" formatWith={[value.length]} />
              : <span title={selectedFileName}>{selectedFileName}</span>
            : (
              <>
                <strong className={styles.labelText}><SimpleText textKey="FileUploader_ChooseAFile" /></strong>
                <span className={styles.dragText}> <SimpleText textKey="FileUploader_DragAFile" /></span>
              </>
            )
          }
        </span>
        {value && (
          <span
            role="button"
            onClick={onFileClear}
            onKeyDown={onCancelBtnKeyDown}
            tabIndex="0"
            className={styles.btnCancel}
          >
            <SimpleText textKey="ButtonText_Cancel" />
          </span>
        )}
      </label>
    </div>
  );
};

FileInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      item: PropTypes.func.isRequired,
      length: PropTypes.number.isRequired,
    }),
  ]),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  additionalInfoData: PropTypes.shape({
    formikRef: PropTypes.object,
    onBlurAdditionalInfo: PropTypes.func,  
  }),
};

export default memo(FileInput);

function getSelectedFileName(value, multiple) {
  if (!value)
    return null;

  if (multiple)
    return value.length ? value[0].name : null;

  return value.name;
}

function handleDragOver(e) {
  e.preventDefault();
}

function handleDragEnter(e) {
  e.preventDefault();
  e.currentTarget.classList.add(styles.dragOver);
}

function handleDragLeave(e) {
  !e.currentTarget.contains(e.relatedTarget) && e.currentTarget.classList.remove(styles.dragOver);
}
